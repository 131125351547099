<template>
    <div class="div">
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="绑定手机号"
                z-index="99"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div>
            <p class="bind_iphone">
                <img src="../../assets/images/bind_iphone_img.png" alt="" class="bind_iphone_img">
            </p>
            <p class="iphone" v-if="user_infoList.phone">+86 {{user_infoList.phone}}</p>
            <p class="iphone-button" @click="bindingClick">
                更换绑定
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Replace",
        data(){
            return{
                user_infoList:[],
            }
        },
        created() {
            this.user_info()
        },
        methods:{
            user_info() {
                this.$axios.get('/api/user_info')
                    .then(res => {
                        var res = res.data.data
                        var tel = res.phone;
                        tel = "" + tel;
                        res.phone = tel.replace(tel.substring(3, 7), "****")
                        this.user_infoList = res
                    })
            },
            bindingClick(){
                this.$router.replace({
                    path:'/replacebinding',
                })
            }
        }
    }
</script>

<style scoped>
    .iphone-button{
        width:670px;
        height:88px;
        background:rgba(77,119,253,1);
        border-radius:44px;
        font-size:28px;
        font-weight:400;
        color:rgba(255,255,255,1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 70px;
    }
    .iphone{
        color: #0A1C33;
        font-size: 30px;
        text-align: center;
    }
    .bind_iphone{
        text-align: center;
        margin: 51px 0 170px;
    }
    .bind_iphone_img {
        width:156px;
        height:156px;

    }
</style>